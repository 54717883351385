var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.userAccess.canCreate)?_c('v-row',{staticClass:"mb-4"},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","elevation":"1"},on:{"click":function($event){return _vm.$router.push('create')}}},[_vm._v(" "+_vm._s(_vm.$_strings.p2pLending.CREATE_NEW)+" ")])],1)],1):_vm._e(),_c('v-sheet',{staticClass:"pa-4",attrs:{"rounded":""}},[_c('v-row',[_c('v-col',{staticClass:"pa-0"},[_c('FilterP2PLending',{attrs:{"filters":_vm.filters,"showFilterBy":false},on:{"fetchData":_vm.setFilter}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"calculate-widths":"","loading":_vm.isLoading,"headers":_vm.displayedHeaders,"items":_vm.items,"item-key":"invoiceNo","server-items-length":_vm.listTotalEntry,"options":_vm.pagination,"footer-props":{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': _vm.$_item_per_page,
            disablePagination: _vm.isLoading,
            disableItemsPerPage: _vm.isLoading
          }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.dateFormat(item.createdAt)))])]}},{key:"item.totalAmount",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"font-weight-bold mb-0"},[_vm._v("Rp. "+_vm._s(_vm.formatCurrency(item.totalAmount)))]),_c('p',{staticClass:"mt-0"},[_vm._v("Rp. "+_vm._s(_vm.formatCurrency(item.totalNett)))])]}},{key:"item.status",fn:function(ref){
          var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.p2pLendingStatus(item.status)))])]}},{key:"item.actions",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.userAccess.canView)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.action(item, 'detail-p2p-lending')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-magnify ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Lihat Detail")])]),(item.status.toLowerCase() === 'draft')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(_vm.userAccess.canUpdate)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){return _vm.action(item, 'edit-p2p-lending')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.EDIT))])]):_vm._e(),(item.status.toLowerCase() === 'draft')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([(_vm.userAccess.canDelete)?{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":item.isDeleting}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"},on:{"click":function (){ return _vm.deleteP2pLending(item); }}},[_vm._v(" mdi-trash-can ")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$_strings.common.DELETE))])]):_vm._e()]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.p2pLending.LIST_P2P_LENDING)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }