<template>
  <v-container fluid>
    <v-row v-if="userAccess.canCreate" class="mb-4">
      <v-col cols="auto">
        <v-btn
          color="primary"
          elevation="1"
          @click="$router.push('create')"
        >
          {{$_strings.p2pLending.CREATE_NEW}}
        </v-btn>
      </v-col>
    </v-row>
    <v-sheet rounded class="pa-4">
      <v-row>
        <v-col class="pa-0">
          <FilterP2PLending
            :filters="filters"
            :showFilterBy="false"
            @fetchData="setFilter"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            calculate-widths
            :loading="isLoading"
            :headers="displayedHeaders"
            :items="items"
            item-key="invoiceNo"
            :server-items-length="listTotalEntry"
            :options.sync="pagination"
            :footer-props="{
              showCurrentPage: true,
              showFirstLastPage: true,
              'items-per-page-options': $_item_per_page,
              disablePagination: isLoading,
              disableItemsPerPage: isLoading
            }"
          >
            <template v-slot:[`item.createdAt`]={item}>
              <span>{{dateFormat(item.createdAt)}}</span>
            </template>
            <template v-slot:[`item.totalAmount`]={item}>
              <p class="font-weight-bold mb-0">Rp. {{formatCurrency(item.totalAmount)}}</p>
              <p class="mt-0">Rp. {{formatCurrency(item.totalNett)}}</p>
            </template>
            <template v-slot:[`item.status`]={item}>
              <span>{{p2pLendingStatus(item.status)}}</span>
            </template>
            <template v-slot:[`item.actions`]={item}>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="action(item, 'detail-p2p-lending')"
                    class="mr-2"
                    v-if="userAccess.canView"
                  >
                    mdi-magnify
                  </v-icon>
                </template>
                <span>Lihat Detail</span>
              </v-tooltip>
              <v-tooltip v-if="item.status.toLowerCase() === 'draft'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    @click="action(item, 'edit-p2p-lending')"
                    v-if="userAccess.canUpdate"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{$_strings.common.EDIT}}</span>
              </v-tooltip>
              <v-tooltip v-if="item.status.toLowerCase() === 'draft'" bottom>
                <template
                  v-if="userAccess.canDelete"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :loading="item.isDeleting"
                  >
                    <v-icon
                      color="red"
                      @click="()=> deleteP2pLending(item)"
                    >
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$_strings.common.DELETE}}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`footer.page-text`]="props">
              <span>
                {{$_strings.p2pLending.LIST_P2P_LENDING}}
                <span v-if="items.length">
                  {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
                </span>
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import {
  dateFormat,
  p2pLendingStatus,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import FilterP2PLending from './FilterP2PLending';

export default {
  name: 'p2p-lending-list',
  components: {
    FilterP2PLending,
  },
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: this.$_strings.p2pLending.NO_P2P_LENDING,
          value: 'documentNo',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.p2pLending.CREATED_AT,
          value: 'createdAt',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.p2pLending.TOTAL_BILL,
          value: 'totalAmount',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.p2pLending.CREATED_BY,
          value: 'createdBy',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
        {
          text: this.$_strings.p2pLending.STATUS,
          value: 'status',
          class: 'white--text primary text-capitalize',
          width: '200px',
        },
        {
          text: this.$_strings.p2pLending.ACTION,
          value: 'actions',
          class: 'white--text primary text-capitalize',
          width: '150px',
        },
      ],
      items: [],
      search: this.$route.query.search || '',
      filters: {
        dateFrom: this.$route.query.dateFrom || dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        dateTo: this.$route.query.dateTo || dayjs().format('YYYY-MM-DD'),
      },
      pagination: defaultPagination(),
      listTotalEntry: 1,
    };
  },
  computed: {
    displayedHeaders() {
      const { canView, canUpdate, canDelete } = this.userAccess;
      return this.headers.filter((header) => {
        if (header.value === 'actions' && !canUpdate && !canDelete && !canView) return;
        return header;
      });
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchData();
      },
      deep: true,
    },
    'filters.dateFrom': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: newVal,
          dateTo: this.filters.dateTo,
        },
      });
    },
    'filters.dateTo': function setQueryDateFrom(newVal) {
      this.$router.replace({
        query: {
          dateFrom: this.filters.dateFrom,
          dateTo: newVal,
        },
      });
    },
  },
  methods: {
    dateFormat,
    dayjs,
    p2pLendingStatus,
    setFilter() {
      if (this.pagination.page > 1) {
        this.pagination.page = 1;
        return;
      }
      this.fetchData();
    },
    setSearch() {
      const { search } = this.$route.query;
      if (search !== this.search) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            search: this.search,
          },
        });
      }
      this.filters.invoiceNo = this.search;
      this.setFilter();
    },
    action(item, pageName) {
      const { id, createdAt, documentNo } = item;
      const {
        dateFrom,
        dateTo,
      } = this.filters;
      const {
        page,
        itemsPerPage: size,
      } = this.pagination;
      this.$router.push({
        name: pageName,
        params: {
          id,
        },
        query: {
          createdAt,
          documentNo,
          dateFrom,
          dateTo,
          page: page - 1,
          size,
          sort: this.sort,
        },
      });
    },
    deleteP2pLending(item) {
      this.$dialog.warning({
        text: 'Anda yakin akan menghapus?',
        title: this.$_strings.common.WARNING,
        persistent: true,
        actions: {
          false: this.$_strings.common.NO,
          true: this.$_strings.common.YES,
        },
      }).then(async (userRes) => {
        if (!userRes) return;
        try {
          this.$set(item, 'isDeleting', true);
          await this.$_services.p2pLending.deleteP2pLending(item.id);
          this.$dialog.notify.success('Berhasil menghapus');
          this.$delete(item, 'isDeleting');
          this.items.splice(this.items.indexOf(item), 1);
        } finally {
          this.$delete(item, 'isDeleting');
        }
      });
    },
    formatCurrency(amount) {
      return amount && amount.toLocaleString('id-ID');
    },
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      this.sort = handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc });
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        sort: this.sort,
      };
      Object.keys(this.filters).forEach((filter) => {
        if (typeof this.filters[filter] === 'object'
        && this.filters[filter] !== null
        && this.filters[filter].length) {
          filters[filter] = `qin(${this.filters[filter].toString()})`;
          return;
        }
        if (this.filters[filter] && filter === 'invoiceNo') {
          filters[filter] = `qlike(${this.filters[filter]})`;
          return;
        }
        if (this.filters[filter] && typeof this.filters[filter] !== 'object') {
          filters[filter] = this.filters[filter];
        }
      });
      try {
        this.isLoading = true;
        const result = await this.$_services.p2pLending.listP2pLending(filters);
        this.items = result.data.contents;
        this.listTotalEntry = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
